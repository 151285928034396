import React from 'react';

class TeamPage extends React.Component {
  render() {
    return (
      <div className="team-page">
        Team page
      </div>
    );
  }
}

export default TeamPage;