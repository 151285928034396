const firebaseConfig = {
  apiKey: "AIzaSyAKbQvRpSkFsBUikFlagbAUvFtlGeqyRkw",
  authDomain: "summer-night-icists.firebaseapp.com",
  databaseURL: "https://summer-night-icists.firebaseio.com",
  projectId: "summer-night-icists",
  storageBucket: "",
  messagingSenderId: "851017044796",
  appId: "1:851017044796:web:f092ddd2aa01272d"
};

export default firebaseConfig;